





























































































































import Vue from "@/translate";
import ManagerMixin from "@/mixins/manager";
import AppCard from "@/components/common/AppCard.vue";
import AppTextField from "@/components/common/AppTextField.vue";
import AppButton from "@/components/common/AppButton.vue";
import { Filter } from "@/models/UI";
import AppGrid from "@/components/common/AppGrid.vue";
import AppLangSelect from "@/components/common/AppLangSelect.vue";
import AppCheckbox from "@/components/common/AppCheckbox.vue";
import AppWorkerSelect from "@/components/common/AppWorkerSelect.vue";
import ManagerClient from "@/client/manager";
import { WorkerSelect } from "@/client/manager/PossibleWorkersQuery";
import AppCustomerSelect from "@/components/common/AppCustomerSelect.vue";
import { SelectCustomer } from "@/models/Customer";
import AppRadioButton from "@/components/common/AppRadioButton.vue";
import AppSeparator from "@/components/common/AppSeparator.vue";
import AppCenterToggle from "@/components/common/AppCenterToggle.vue";
import AppRow from "@/components/common/AppRow.vue";
import AppCol from "@/components/common/AppCol.vue";

function createFilter(type: string, value = true): Filter {
  return {
    name: type,
    value: value,
  };
}

function checkFilter(filters: Filter[], check: string): boolean {
  return filters.filter((x) => x.name == check).length == 1;
}

export default Vue.extend(ManagerMixin).extend({
  name: "ProjectFilterCard",
  components: {
    AppCol,
    AppRow,
    AppCenterToggle,
    AppSeparator,
    AppRadioButton,
    AppCustomerSelect,
    AppWorkerSelect,
    AppCheckbox,
    AppGrid,
    AppButton,
    AppCard,
    AppTextField,
    AppLangSelect,
  },
  data() {
    return {
      open: false,
      abandoned: false,
      include: "OPEN_ONLY",
      idFilter: "",
      content: "",
      source: "",
      target: "",
      customers: [] as SelectCustomer[],
      customer: -1,
      representative: -1,
      workers: [] as WorkerSelect[],
      worker: -1,
      ownerOnly: false,
      filters: [] as Filter[],
    };
  },
  computed: {
    extendedTitle(): string {
      return this.$t("manager.show-project-stats", {
        newCounter: this.$store.state.newCounter,
        openCounter: this.$store.state.openCounter,
        allCounter: this.$store.state.allCounter,
        abandonedCounter: this.$store.state.abandonedCounter,
      }) as string;
    },
    extendedFilterSummary(): string {
      let summary = this.$t("project.filter.summary") as string;
      this.filters.forEach((x) => {
        if ("include" === x.name) {
          summary += " " + this.$t("project.filter.include.name") + ";";
        } else {
          summary += " " + this.$t("project.filter." + x.name) + ";";
        }
      });
      return summary;
    },
    hasFilter(): boolean {
      return this.filters.length > 0;
    },
    hasExtendedFilter(): boolean {
      return (
        this.filters
          .filter((x) => x.name != "id")
          .filter((x) => x.name != "ownerOnly")
          .filter((x) => x.name != "newFilter")
          .filter((x) => x.name != "dueFilter")
          .filter((x) => x.name != "deliveredFilter").length > 0
      );
    },
    hasIncludeFilter(): boolean {
      return this.filters.some((x) => x.name == "include");
    },
    newFilterColor(): string {
      return checkFilter(this.filters, "newFilter") ? "green" : "default";
    },
    dueFilterColor(): string {
      return checkFilter(this.filters, "dueFilter") ? "green" : "default";
    },
    deliveredFilterColor(): string {
      return checkFilter(this.filters, "deliveredFilter") ? "green" : "default";
    },
  },
  async created() {
    await this.loadCustomers();
    await this.loadWorkers();
  },
  methods: {
    async loadWorkers(): Promise<void> {
      this.workers = await ManagerClient.AllWorkersQuery();
    },
    async loadCustomers(): Promise<void> {
      this.customers = await ManagerClient.SelectCustomerQuery();
    },
    clearFilter(clearOwnerOnly = true): void {
      this.idFilter = "";
      this.abandoned = false;
      this.source = "";
      this.target = "";
      this.content = "";
      this.worker = -1;
      this.customer = -1;
      this.representative = -1;
      this.open = false;
      this.include = "OPEN_ONLY";
      if (clearOwnerOnly) {
        this.ownerOnly = false;
      }
      this.filters = [] as Filter[];
      this.filterChanged();
    },
    _simpleFilter(type: string): void {
      this.filters = [] as Filter[];
      this.filters.push(createFilter(type));
      this.filters.push(createFilter("ownerOnly", this.ownerOnly));
      this.$emit("filterChanged", this.filters);
      this.open = false;
    },
    newFilter(): void {
      this.clearFilter(false);
      this._simpleFilter("newFilter");
    },
    dueFilter(): void {
      this.clearFilter(false);
      this._simpleFilter("dueFilter");
    },
    deliveredFilter(): void {
      this.clearFilter(false);
      this._simpleFilter("deliveredFilter");
    },
    filterChanged(): void {
      this.filters = [] as Filter[];
      if (this.idFilter !== "") {
        this.filters.push({
          name: "id",
          value: this.idFilter,
        });
      }
      if (this.content !== "") {
        this.filters.push({
          name: "content",
          value: this.content,
        });
      }
      if (this.abandoned) {
        this.filters.push({
          name: "abandoned",
          value: this.abandoned,
        });
      }
      if (this.source !== "") {
        this.filters.push({
          name: "source",
          value: this.source,
        });
      }
      if (this.target !== "") {
        this.filters.push({
          name: "target",
          value: this.target,
        });
      }
      if ("OPEN_ONLY" !== this.include) {
        this.filters.push({
          name: "include",
          value: this.include,
        });
      }
      if (this.worker !== -1) {
        this.filters.push({
          name: "worker",
          value: this.worker,
        });
      }
      if (this.customer !== -1 && this.customer !== undefined) {
        this.filters.push({
          name: "customer",
          value: this.customer,
        });
      }
      if (this.representative !== -1 && this.representative !== undefined) {
        this.filters.push({
          name: "representative",
          value: this.representative,
        });
      }
      this.$emit("filterChanged", this.filters);
      this.open = false;
    },
    expandFilter(): void {
      this.open = true;
    },
    collapseFilter(): void {
      this.open = false;
    },
  },
});
